const json = {
  "charset": "utf-8",
  "headers": {
    "Content-Type": "text/plain; charset=UTF-8",
    "Plural-Forms": "nplurals=2; plural=(n != 1);"
  },
  "translations": {
    "": {
      "": {
        "msgid": "",
        "msgstr": [
          "Content-Type: text/plain; charset=UTF-8\nPlural-Forms: nplurals=2; plural=(n != 1);\n"
        ]
      },
      "Administration": {
        "msgid": "Administration",
        "msgstr": [
          "Verwaltung"
        ]
      },
      "All dashboards.": {
        "msgid": "All dashboards.",
        "msgstr": [
          "Alle Dashboards"
        ]
      },
      "Applications & Evidence": {
        "msgid": "Applications & Evidence",
        "msgstr": [
          "Anwendungen und Nachweise"
        ]
      },
      "Applications & Evidence.": {
        "msgid": "Applications & Evidence.",
        "msgstr": [
          "Anwendungen und Nachweise:"
        ]
      },
      "Automation": {
        "msgid": "Automation",
        "msgstr": [
          "Automatisierung"
        ]
      },
      "Automation for enterprise wide governance and self-service.": {
        "msgid": "Automation for enterprise wide governance and self-service.",
        "msgstr": [
          "Automatisierung für unternehmensweite Governance und Self-Service."
        ]
      },
      "Automation.": {
        "msgid": "Automation.",
        "msgstr": [
          "Automatisierung"
        ]
      },
      "Business Services": {
        "msgid": "Business Services",
        "msgstr": [
          "Business Services"
        ]
      },
      "Business services.": {
        "msgid": "Business services.",
        "msgstr": [
          "Business Services."
        ]
      },
      "Cloud": {
        "msgid": "Cloud",
        "msgstr": [
          "Cloud"
        ]
      },
      "Cloud Settings": {
        "msgid": "Cloud Settings",
        "msgstr": [
          "Cloud-Einstellungen"
        ]
      },
      "Contracts (Beta)": {
        "msgid": "Contracts (Beta)",
        "msgstr": [
          "Verträge (Beta)"
        ]
      },
      "Cost Optimization": {
        "msgid": "Cost Optimization",
        "msgstr": [
          "Kostenoptimierung"
        ]
      },
      "Custom Dashboards": {
        "msgid": "Custom Dashboards",
        "msgstr": [
          "Benutzerdefinierte Dashboards"
        ]
      },
      "Dashboards": {
        "msgid": "Dashboards",
        "msgstr": [
          "Dashboards"
        ]
      },
      "Data Collection": {
        "msgid": "Data Collection",
        "msgstr": [
          "Datenerfassung"
        ]
      },
      "Discover and analyze your installed enterprise software.": {
        "msgid": "Discover and analyze your installed enterprise software.",
        "msgstr": [
          "Ermitteln und analysieren Sie Ihre installierte Unternehmenssoftware."
        ]
      },
      "Hardware": {
        "msgid": "Hardware",
        "msgstr": [
          "Hardware"
        ]
      },
      "Hardware.": {
        "msgid": "Hardware.",
        "msgstr": [
          "Hardware."
        ]
      },
      "IT Asset Management Settings": {
        "msgid": "IT Asset Management Settings",
        "msgstr": [
          "Einstellungen für IT Asset Management"
        ]
      },
      "IT Asset Requests": {
        "msgid": "IT Asset Requests",
        "msgstr": [
          "IT Asset Requests"
        ]
      },
      "IT Visibility": {
        "msgid": "IT Visibility",
        "msgstr": [
          "IT Visibility"
        ]
      },
      "IT Visibility Devices": {
        "msgid": "IT Visibility Devices",
        "msgstr": [
          "IT Visibility-Geräte"
        ]
      },
      "IT Visibility Evidence": {
        "msgid": "IT Visibility Evidence",
        "msgstr": [
          "Nachweise IT Visibility"
        ]
      },
      "IT Visibility Inventory Status": {
        "msgid": "IT Visibility Inventory Status",
        "msgstr": [
          "Inventarisierungsstatus IT Visibility"
        ]
      },
      "IT Visibility Inventory Tasks": {
        "msgid": "IT Visibility Inventory Tasks",
        "msgstr": [
          "Inventarisierungsaufgaben IT Visibility"
        ]
      },
      "Identity Management": {
        "msgid": "Identity Management",
        "msgstr": [
          "Identitätsmanagement"
        ]
      },
      "Inventory": {
        "msgid": "Inventory",
        "msgstr": [
          "Inventar"
        ]
      },
      "Inventory.": {
        "msgid": "Inventory.",
        "msgstr": [
          "Inventar."
        ]
      },
      "License (Beta)": {
        "msgid": "License (Beta)",
        "msgstr": [
          "Lizenz (Beta)"
        ]
      },
      "Licenses": {
        "msgid": "Licenses",
        "msgstr": [
          "Lizenzen"
        ]
      },
      "Licenses.": {
        "msgid": "Licenses.",
        "msgstr": [
          "Lizenzen."
        ]
      },
      "Manage Flexera platform for your organization.": {
        "msgid": "Manage Flexera platform for your organization.",
        "msgstr": [
          "Flexera-Plattform für Ihr Unternehmen verwalten"
        ]
      },
      "Manage your organization's SaaS spend.": {
        "msgid": "Manage your organization's SaaS spend.",
        "msgstr": [
          "SaaS-Ausgaben Ihres Unternehmens verwalten"
        ]
      },
      "Organization": {
        "msgid": "Organization",
        "msgstr": [
          "Unternehmen"
        ]
      },
      "Organization.": {
        "msgid": "Organization.",
        "msgstr": [
          "Unternehmen."
        ]
      },
      "Plugins": {
        "msgid": "Plugins",
        "msgstr": [
          "Plugins"
        ]
      },
      "Portal Management": {
        "msgid": "Portal Management",
        "msgstr": [
          "Portal-Verwaltung"
        ]
      },
      "Procurement": {
        "msgid": "Procurement",
        "msgstr": [
          "Beschaffung"
        ]
      },
      "Procurement.": {
        "msgid": "Procurement.",
        "msgstr": [
          "Beschaffung."
        ]
      },
      "Purchases & Vendors (Beta)": {
        "msgid": "Purchases & Vendors (Beta)",
        "msgstr": [
          "Käufe und Anbieter (Beta)"
        ]
      },
      "Reporting": {
        "msgid": "Reporting",
        "msgstr": [
          "Berichte"
        ]
      },
      "Reporting.": {
        "msgid": "Reporting.",
        "msgstr": [
          "Berichte."
        ]
      },
      "Request Portal": {
        "msgid": "Request Portal",
        "msgstr": [
          "Portal für Anträge"
        ]
      },
      "SBOM Management": {
        "msgid": "SBOM Management",
        "msgstr": [
          "SBOM-Verwaltung"
        ]
      },
      "SBOM Management.": {
        "msgid": "SBOM Management.",
        "msgstr": [
          "SBOM-Verwaltung."
        ]
      },
      "SaaS": {
        "msgid": "SaaS",
        "msgstr": [
          "SaaS"
        ]
      },
      "SaaS Applications": {
        "msgid": "SaaS Applications",
        "msgstr": [
          "SaaS-Anwendungen"
        ]
      },
      "SaaS License Management": {
        "msgid": "SaaS License Management",
        "msgstr": [
          "SaaS-Lizenzmanagement"
        ]
      },
      "SaaS Settings": {
        "msgid": "SaaS Settings",
        "msgstr": [
          "SaaS-Einstellungen"
        ]
      },
      "Software Vulnerability Research.": {
        "msgid": "Software Vulnerability Research.",
        "msgstr": [
          "Software-Schwachstellenforschung."
        ]
      },
      "Technology Spend": {
        "msgid": "Technology Spend",
        "msgstr": [
          "Technologie-Ausgaben"
        ]
      },
      "Technopedia": {
        "msgid": "Technopedia",
        "msgstr": [
          "Technopedia"
        ]
      },
      "Technopedia Dashboard.": {
        "msgid": "Technopedia Dashboard.",
        "msgstr": [
          "Technopedia-Dashboard."
        ]
      },
      "Understand your cloud costs and usage.": {
        "msgid": "Understand your cloud costs and usage.",
        "msgstr": [
          "Gewinnen Sie Einblick in Ihre Cloud-Kosten und -Nutzung."
        ]
      },
      "Vulnerabilities": {
        "msgid": "Vulnerabilities",
        "msgstr": [
          "Schwachstellen"
        ]
      }
    }
  }
};
export default json;
