import { NavProvider } from '@flexera/shell.navigation';
import React, { FC, PropsWithChildren } from 'react';
import { SetupNavMenu } from './SetupNavMenu';

export const Nav: FC<PropsWithChildren> = ({ children }) => (
	<NavProvider>
		<SetupNavMenu />
		{children}
	</NavProvider>
);
