const makeId = (id: string) => `optima.bootstrap${id}`;

export const BASE = makeId('');
export const LOADING = makeId('.loading');

// Dashboards -> Cloud
export const DASHBOARDS = makeId('/dashboards');
export const LEGACY_DASHBOARDS = makeId('/legacy-dashboard');

// Dashboards -> Cloud -> Anomalies
export const ANOMALIES = makeId('/anomalies');

// Cloud -> Billing Centers
export const LEGACY_BILLING = makeId('/legacy-billing');
export const BILLING_CENTERS_BASE = makeId('/billing-centers-base');
export const BILLING_CENTERS = makeId('/billing-centers');
export const CCO_TABULAR_VIEW = makeId('/tabular-view');
export const BILLING_TABLE = makeId('/billing-table');
export const BILLING_RULES = makeId('/billing-rule');
export const BILLING_CHILD = makeId('/billing-child');

// Cloud -> Cost Optimization
export const OVERVIEW = makeId('/optimization');
export const OVERVIEW_RECOMMENDATIONS = makeId('/optimization-recommendations');

// Cloud -> Reserved Instances
export const RIS = makeId('/ris');

// Cloud -> Savings Plans
export const SAVINGS_PLANS = makeId('/savings-plans');

// Cloud -> Program Adjustments
export const ADJUSTMENTS = makeId('/adjustments');

// Cloud -> Budgets
export const BUDGETS = makeId('/budgets');
export const BUDGET = makeId('/budget-detail');

// Cloud -> Migration Planning
export const MIGRATION_PLANNING = makeId('/migration-planning');

// Admin -> Cloud Settings
export const BILL_PROCESSING = makeId('/bill-processing');
export const BILLING_CONFIG = makeId('/billing-configuration');
export const TAG_DIMENSIONS = makeId('/tag-dimensions');
export const RULE_BASED_DIMENSIONS = makeId('/rule-based-dimensions');

// Cloud -> Create recommendation sets
export const CREATE_RECOMMENDATION_SET = makeId('/create-recommendation-set');
export const EDIT_RECOMMENDATION_SET = makeId('/edit-recommendation-set');
export const RECOMMENDATION_SET = makeId('/recommendation-set');
