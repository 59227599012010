import camelCase from 'lodash.camelcase';
import React, { FunctionComponent } from 'react';
import ReactDOM from 'react-dom/client';
import { StyleSheetManager, ThemeProvider } from 'styled-components';
import { BaseTheme } from '@flexera/ui.component-library';
import { WrapperRootProps, Constructor, WrapOptions } from './types';

const WrapperRoot = ({ root, isolateStyles, children }: WrapperRootProps) => {
	const resetCSS = `:host { all: initial }`;
	return (
		<React.StrictMode>
			{isolateStyles && <style>{resetCSS}</style>}
			<StyleSheetManager target={(root as unknown) as HTMLElement}>
				{children}
			</StyleSheetManager>
		</React.StrictMode>
	);
};

export const wrap = <ComponentProps,>(
	WrappedComponent: FunctionComponent<ComponentProps>,
	name = 'fusion-component',
	options: WrapOptions<ComponentProps> = {
		isolateStyles: true
	}
) => {
	if (customElements.get(name)) {
		// eslint-disable-next-line no-console
		console.warn(`Component ${name} has already been registered`);
		return {
			ElementClass: (customElements.get(name) as unknown) as Constructor<
				HTMLElement & ComponentProps
			>
		};
	}

	class ElementClass extends HTMLElement {
		static get observedAttributes() {
			return options?.observedAttributes ?? [];
		}

		shadow: ShadowRoot;

		reactRoot: ReactDOM.Root;

		props: {
			[K in keyof ComponentProps]: ComponentProps[K];
		} = {} as { [K in keyof ComponentProps]: ComponentProps[K] };

		constructor() {
			super();

			this.shadow = this.attachShadow({ mode: options.shadowType || 'open' });
			this.reactRoot = ReactDOM.createRoot(this.shadow);
		}

		connectedCallback() {
			this.render();
		}

		attributeChangedCallback(
			attributeKey: string,
			_oldValue: string | null,
			newValue: string | null
		) {
			const camelCasedKey = camelCase(attributeKey) as keyof ComponentProps;
			this.props[camelCasedKey] = newValue as ComponentProps[keyof ComponentProps];
			this.render();
		}

		disconnectedCallback() {
			// sync unmount
			setTimeout(() => this.reactRoot.unmount(), 0);
		}

		render() {
			if (this.isConnected) {
				this.reactRoot.render(
					<WrapperRoot root={this.shadow} isolateStyles={options.isolateStyles}>
						<ThemeProvider theme={BaseTheme}>
							<WrappedComponent {...this.props} />
						</ThemeProvider>
					</WrapperRoot>
				);
			}
		}
	}

	(options?.observedAttributes ?? []).forEach((key) => {
		const camelCasedKey = camelCase(key) as keyof ComponentProps;

		Object.defineProperty(ElementClass.prototype, camelCasedKey, {
			get(this: ElementClass) {
				// eslint-disable-next-line no-unused-expressions
				this.props[camelCasedKey];
			},
			set(value: ComponentProps[typeof camelCasedKey]) {
				this.props[camelCasedKey] = value;
				this.render();
			}
		});
	});

	(options?.accessors ?? []).forEach((key) => {
		Object.defineProperty(ElementClass.prototype, key, {
			get() {
				// eslint-disable-next-line no-unused-expressions
				this.props[key];
			},
			set(value: ComponentProps[typeof key]) {
				this.props[key] = value;
				this.render();
			}
		});
	});

	customElements.define(name, ElementClass);

	return {
		ElementClass: (ElementClass as unknown) as Constructor<
			HTMLElement & ComponentProps
		>
	};
};
