const json = {
  "charset": "utf-8",
  "headers": {
    "Content-Type": "text/plain; charset=UTF-8",
    "Plural-Forms": "nplurals=2; plural=(n != 1);"
  },
  "translations": {
    "": {
      "": {
        "msgid": "",
        "msgstr": [
          "Content-Type: text/plain; charset=UTF-8\nPlural-Forms: nplurals=2; plural=(n != 1);\n"
        ]
      },
      "(Now) software vendors need to think twice about scaring or bullying us during an audit. Flexera’s given us the data and confidence to know exactly what software we have and what we use.": {
        "msgid": "(Now) software vendors need to think twice about scaring or bullying us during an audit. Flexera’s given us the data and confidence to know exactly what software we have and what we use.",
        "msgstr": [
          "Softwareanbieter müssen es sich (jetzt) zweimal überlegen, ob sie uns bei einem Audit Angst einjagen oder schikanieren wollen. Dank der Daten von Flexera wissen wir jetzt sehr genau, welche Software wir haben und welche wir nutzen."
        ]
      },
      "Case Studies": {
        "msgid": "Case Studies",
        "msgstr": [
          "Fallbeispiele"
        ]
      },
      "Catch up on the latest news and content.": {
        "msgid": "Catch up on the latest news and content.",
        "msgstr": [
          "Informieren Sie sich über die neuesten Nachrichten und Inhalte."
        ]
      },
      "Chief Procurement Officer": {
        "msgid": "Chief Procurement Officer",
        "msgstr": [
          "Leiter des Einkaufs (CPO)"
        ]
      },
      "Close": {
        "msgid": "Close",
        "msgstr": [
          "Schließen"
        ]
      },
      "Cloud Cloud Cost Optimization": {
        "msgid": "Cloud Cloud Cost Optimization",
        "msgstr": [
          "Cloud-Version von Cloud Cost Optimization"
        ]
      },
      "Cloud Operations Engineer": {
        "msgid": "Cloud Operations Engineer",
        "msgstr": [
          "Cloud Operations Engineer"
        ]
      },
      "Contact Us": {
        "msgid": "Contact Us",
        "msgstr": [
          "Kontakt"
        ]
      },
      "Contact Us Link. This link will open in a new window.": {
        "msgid": "Contact Us Link. This link will open in a new window.",
        "msgstr": [
          "Link \"Kontakt\". Dieser Link wird in einem neuen Fenster geöffnet."
        ]
      },
      "Data Privacy": {
        "msgid": "Data Privacy",
        "msgstr": [
          "Datenschutz"
        ]
      },
      "Data Privacy Link. This link will open in a new window.": {
        "msgid": "Data Privacy Link. This link will open in a new window.",
        "msgstr": [
          "Link zur Datenschutzrichtlinie. Dieser Link wird in einem neuen Fenster geöffnet."
        ]
      },
      "Datasheets": {
        "msgid": "Datasheets",
        "msgstr": [
          "Datenblätter"
        ]
      },
      "Deep dives into the features and benefit of Flexere One solutions.": {
        "msgid": "Deep dives into the features and benefit of Flexere One solutions.",
        "msgstr": [
          "Tiefgehende Einblicke in die Funktionen und Vorteile der Flexere-One-Lösungen."
        ]
      },
      "Desktop Services Supervisor": {
        "msgid": "Desktop Services Supervisor",
        "msgstr": [
          "Desktop Services Supervisor"
        ]
      },
      "Examining a range of IT challenges and how Flexera One solves them.": {
        "msgid": "Examining a range of IT challenges and how Flexera One solves them.",
        "msgstr": [
          "Untersuchung einer Reihe von IT-Herausforderungen und wie Flexera One diese meistert."
        ]
      },
      "FLEXINSIGHTS": {
        "msgid": "FLEXINSIGHTS",
        "msgstr": [
          "FLEXINSIGHTS"
        ]
      },
      "Find out more": {
        "msgid": "Find out more",
        "msgstr": [
          "Weitere Informationen"
        ]
      },
      "Flexera One Overview": {
        "msgid": "Flexera One Overview",
        "msgstr": [
          "Übersicht über Flexera One "
        ]
      },
      "Flexera One Solutions": {
        "msgid": "Flexera One Solutions",
        "msgstr": [
          "Lösungen von Flexera One "
        ]
      },
      "Flexera One helps you maximize value from desktop to cloud, providing you with a unified view of your technology landscape.": {
        "msgid": "Flexera One helps you maximize value from desktop to cloud, providing you with a unified view of your technology landscape.",
        "msgstr": [
          "Mit Flexera One maximieren Sie Ihren Nutzen vom Desktop bis zur Cloud durch eine zentrale Sicht auf Ihre Technologielandschaft."
        ]
      },
      "Flexera One’s Cloud Cost Optimization capability is a cost management and optimization solution that enables enterprises to cut costs and ensure efficiencies.": {
        "msgid": "Flexera One’s Cloud Cost Optimization capability is a cost management and optimization solution that enables enterprises to cut costs and ensure efficiencies.",
        "msgstr": [
          "Die Kostenmanagement- und Optimierungslösung von Flexera One heißt Cloud Cost Optimization und sie ermöglicht es Unternehment, die Kosten zu senken und Effizienz zu gewährleisten."
        ]
      },
      "Flexera One’s IT Asset Request capability enables you to rapidly deliver enterprise software to your employees.": {
        "msgid": "Flexera One’s IT Asset Request capability enables you to rapidly deliver enterprise software to your employees.",
        "msgstr": [
          "Mit der Funktion zur IT-Asset-Anforderung von Flexera One können Sie Ihren Mitarbeitern Unternehmenssoftware schnell bereitstellen."
        ]
      },
      "Flexera One’s IT Visibility gives you a single source of truth for your entire IT estate.": {
        "msgid": "Flexera One’s IT Visibility gives you a single source of truth for your entire IT estate.",
        "msgstr": [
          "Durch die IT-Visibility-Funktion von Flexera One erhalten Sie Einblick in Ihren gesamten IT-Bestand aus einer zentralen Datenquelle."
        ]
      },
      "Flexera One’s SaaS capabilities gives you visibility and control over your SaaS landscape.": {
        "msgid": "Flexera One’s SaaS capabilities gives you visibility and control over your SaaS landscape.",
        "msgstr": [
          "Mit den SaaS-Funktionen von Flexera One erhalten Sie Transparenz und damit Kontrolle über Ihre SaaS-Landschaft."
        ]
      },
      "Flexera has given me the necessary data quality for me to be able to provide the financial transparency and overview required in a global software lifecycle management department.": {
        "msgid": "Flexera has given me the necessary data quality for me to be able to provide the financial transparency and overview required in a global software lifecycle management department.",
        "msgstr": [
          "Durch Flexera erhalte ich die Datenqualität, die zur Gewährleistung finanzieller Transparenz und Übersichtlichkeit in einer Abteilung für das Software-Lifecycle-Management mit globaler Zuständigkeit erforderlich ist."
        ]
      },
      "Flexera helped us get insights on our cloud spend and be more efficient by reducing waste.": {
        "msgid": "Flexera helped us get insights on our cloud spend and be more efficient by reducing waste.",
        "msgstr": [
          "Flexera hat uns geholfen, Einblick in unsere Cloud-Ausgaben zu erhalten und durch die Reduzierung unnützer Dinge effizienter zu werden."
        ]
      },
      "Flexera is such a flexible tool, allowing high-level views.": {
        "msgid": "Flexera is such a flexible tool, allowing high-level views.",
        "msgstr": [
          "Flexera ist ein hochflexibles Werkzeug, das einen zentralen Überblick bietet."
        ]
      },
      "Flexera. All rights reserved.": {
        "msgid": "Flexera. All rights reserved.",
        "msgstr": [
          "Flexera. Alle Rechte vorbehalten."
        ]
      },
      "Hardware Models": {
        "msgid": "Hardware Models",
        "msgstr": [
          "Hardware-Modelle"
        ]
      },
      "IT Asset Requests": {
        "msgid": "IT Asset Requests",
        "msgstr": [
          "IT Asset Requests"
        ]
      },
      "IT Security & Compliance Manager": {
        "msgid": "IT Security & Compliance Manager",
        "msgstr": [
          "Manager für IT-Sicherheit und Compliance"
        ]
      },
      "IT Visibility": {
        "msgid": "IT Visibility",
        "msgstr": [
          "IT Visibility"
        ]
      },
      "It’s saving us valuable time ... our team has more time to focus on our other strategic software initiatives.": {
        "msgid": "It’s saving us valuable time ... our team has more time to focus on our other strategic software initiatives.",
        "msgstr": [
          "Es spart uns wertvolle Zeit... unser Team kann mehr Zeit auf unsere anderen strategischen Softwareinitiativen verwenden."
        ]
      },
      "Join The Flexera One Community": {
        "msgid": "Join The Flexera One Community",
        "msgstr": [
          "Werden Sie Mitglied im Flexera-One-Forum."
        ]
      },
      "Latest News": {
        "msgid": "Latest News",
        "msgstr": [
          "Was gibt es Neues?"
        ]
      },
      "Latest Videos": {
        "msgid": "Latest Videos",
        "msgstr": [
          "Neueste Videos"
        ]
      },
      "Learn more about Flexera One": {
        "msgid": "Learn more about Flexera One",
        "msgstr": [
          "Weitere Informationen zu Flexera One"
        ]
      },
      "Lifecycle Dates": {
        "msgid": "Lifecycle Dates",
        "msgstr": [
          "Lebenszyklus-Daten"
        ]
      },
      "MANUFACTURERS": {
        "msgid": "MANUFACTURERS",
        "msgstr": [
          "HERSTELLER"
        ]
      },
      "MARKETING": {
        "msgid": "MARKETING",
        "msgstr": [
          "MARKETING"
        ]
      },
      "Market Data Points": {
        "msgid": "Market Data Points",
        "msgstr": [
          "Marktdatenpunkte"
        ]
      },
      "Maximize the business value of your IT investments": {
        "msgid": "Maximize the business value of your IT investments",
        "msgstr": [
          "Maximieren Sie den geschäftlichen Nutzen Ihrer IT-Investitionen"
        ]
      },
      "Our Technopedia IT asset database spans:": {
        "msgid": "Our Technopedia IT asset database spans:",
        "msgstr": [
          "Unsere Technopedia-IT-Datenbank umfasst:"
        ]
      },
      "PRODUCTS": {
        "msgid": "PRODUCTS",
        "msgstr": [
          "PRODUKTE"
        ]
      },
      "Product Usage Rights": {
        "msgid": "Product Usage Rights",
        "msgstr": [
          "Produktnutzungsrechte"
        ]
      },
      "Read Testimonials": {
        "msgid": "Read Testimonials",
        "msgstr": [
          "Kundenmeinungen lesen"
        ]
      },
      "Read how Flexera One solutions have benefited enterprises like yours.": {
        "msgid": "Read how Flexera One solutions have benefited enterprises like yours.",
        "msgstr": [
          "Erfahren Sie, wie Unternehmen wie das Ihre von Flexera-One-Lösungen profitieren."
        ]
      },
      "SIGNUP CARD": {
        "msgid": "SIGNUP CARD",
        "msgstr": [
          "REGISTRIERUNG"
        ]
      },
      "SKUs": {
        "msgid": "SKUs",
        "msgstr": [
          "Artikelnummern (SKU)"
        ]
      },
      "SaaS Manager": {
        "msgid": "SaaS Manager",
        "msgstr": [
          "SaaS-Manager"
        ]
      },
      "See Our Data": {
        "msgid": "See Our Data",
        "msgstr": [
          "Ein Blick auf unsere Daten"
        ]
      },
      "See the impact Flexera One Solutions can have on your IT estate.": {
        "msgid": "See the impact Flexera One Solutions can have on your IT estate.",
        "msgstr": [
          "Sehen Sie selbst, welchen Einfluss Lösungen von Flexera One auf Ihre IT-Landschaft haben können."
        ]
      },
      "Senior Director of Enterprise Data and Systems": {
        "msgid": "Senior Director of Enterprise Data and Systems",
        "msgstr": [
          "Leitender Direktor für Unternehmensdaten und -systeme"
        ]
      },
      "Senior Manager, Technical Product": {
        "msgid": "Senior Manager, Technical Product",
        "msgstr": [
          "Leiter (Senior Manager) Technisches Produkt"
        ]
      },
      "Senior Software Contract Manager": {
        "msgid": "Senior Software Contract Manager",
        "msgstr": [
          "Leiter (Senior Manager) Software-Verträge"
        ]
      },
      "Software Releases": {
        "msgid": "Software Releases",
        "msgstr": [
          "Software-Versionen"
        ]
      },
      "Solution Briefs": {
        "msgid": "Solution Briefs",
        "msgstr": [
          "Lösungsüberblicke"
        ]
      },
      "TESTIMONIALS": {
        "msgid": "TESTIMONIALS",
        "msgstr": [
          "KUNDENMEINUNGEN"
        ]
      },
      "Technical Architect/Developer": {
        "msgid": "Technical Architect/Developer",
        "msgstr": [
          "Technischer Architekt/Entwickler"
        ]
      },
      "The Flexera tools...took the guessing out of the equation. It enabled us to work with our migration partner to prioritize the applications and to correctly size and secure the applications in the cloud.": {
        "msgid": "The Flexera tools...took the guessing out of the equation. It enabled us to work with our migration partner to prioritize the applications and to correctly size and secure the applications in the cloud.",
        "msgstr": [
          "Die Flexera-Tools... haben das Rätselraten überflüssig gemacht. Sie ermöglichten es uns, zusammen mit unserem Migrationspartner Anwendungen zu priorisieren und die Anwendungen in der Cloud richtig zu dimensionieren und zu sichern."
        ]
      },
      "The ability to view all software and track the assessment of all vulnerabilities and patches in one place is essential to ensure NERC compliance.": {
        "msgid": "The ability to view all software and track the assessment of all vulnerabilities and patches in one place is essential to ensure NERC compliance.",
        "msgstr": [
          "Die Möglichkeit, die gesamte Software zu überblicken und die Bewertung aller Schwachstellen und Patches an einem Ort zu verfolgen, ist für die Einhaltung der NERC-Richtlinien unerlässlich."
        ]
      },
      "The advantage of Flexera One SaaS Management is I can get that analysis on demand relatively quick. I can get it today, I can get it tomorrow; it doesn’t require data extraction. It’s literally point-and-click and you pretty much have your insight.": {
        "msgid": "The advantage of Flexera One SaaS Management is I can get that analysis on demand relatively quick. I can get it today, I can get it tomorrow; it doesn’t require data extraction. It’s literally point-and-click and you pretty much have your insight.",
        "msgstr": [
          "Der Vorteil von Flexera One SaaS Management ist, dass ich diese Analyse bei Bedarf relativ schnell bekommen kann. Ich kann sie heute bekommen, ich kann sie morgen bekommen; es ist keine Datenextraktion nötig. Einfach darauf \"deuten und klicken\" und man hat einen ziemlich genauen Einblick."
        ]
      },
      "There are many ways Flexera One solutions can help you make the most from every IT dollar. Take the Flexera One tour and check out the resources below to find out how to maximize your Flexera One solutions.": {
        "msgid": "There are many ways Flexera One solutions can help you make the most from every IT dollar. Take the Flexera One tour and check out the resources below to find out how to maximize your Flexera One solutions.",
        "msgstr": [
          "Es gibt viele Möglichkeiten, wie Sie mit Lösungen von Flexera One das Beste aus jedem Euro für die IT herausholen können. Lassen Sie sich Flexera One vorführen und experimentieren Sie mit den unten stehenden Ressourcen, um herauszufinden, wie Sie Ihre Flexera-One-Lösungen optimal nutzen können."
        ]
      },
      "Tour Flexera One": {
        "msgid": "Tour Flexera One",
        "msgstr": [
          "Flexera-One-Demo anfordern"
        ]
      },
      "UPDATES PER DAY": {
        "msgid": "UPDATES PER DAY",
        "msgstr": [
          "UPDATES PRO TAG"
        ]
      },
      "VIDEO LIBRARY": {
        "msgid": "VIDEO LIBRARY",
        "msgstr": [
          "VIDEO-BIBLIOTHEK"
        ]
      },
      "View More": {
        "msgid": "View More",
        "msgstr": [
          "Mehr anzeigen"
        ]
      },
      "What our customers say": {
        "msgid": "What our customers say",
        "msgstr": [
          "Das sagen unsere Kunden"
        ]
      }
    }
  }
};
export default json;
