import { PropsWithChildren } from 'react';

// Adapted from https://gist.github.com/kuroski/9a7ae8e5e5c9e22985364d1ddbf3389d
type CamelToDashCase<S extends string> = S extends `${infer T}${infer U}`
	? `${T extends Capitalize<T> ? '-' : ''}${Lowercase<T>}${CamelToDashCase<U>}`
	: S;

type KeysToDashCase<T> = {
	[K in keyof T as CamelToDashCase<string & K>]: T[K];
};

export interface WrapOptions<ComponentProps> {
	/**
	 * inherit host styles or not, default is not
	 */
	isolateStyles?: boolean | undefined;
	/**
	 * open or close the shadowDOM, default is open
	 */
	shadowType?: ShadowRootMode;
	/**
	 * for a simple value (boolean, string, number), if you need a complex value use accessors
	 */
	observedAttributes?: (keyof KeysToDashCase<ComponentProps> & string)[];
	/**
	 * for complex values (function, array, object)
	 */
	accessors?: (keyof ComponentProps & string)[];
}

export type Constructor<T> = {
	new (): T;
};

export type WrapperRootProps = PropsWithChildren<{
	root?: HTMLElement | ShadowRoot;
	isolateStyles?: boolean | undefined;
}>;
