import { useEffect } from 'react';
import { t } from 'ttag';

import { useAuthenticated } from '@flexera/auth.base';
import { loadingItem, useNavItems } from '@flexera/shell.navigation';
import { OrgsLoading, useCurrentOrg, useOrgId } from '@flexera/shell.orgs';
import { IconMdWorkspaces } from '@flexera/ui.component-library';
import { Environment, environment } from '@flexera/lib.environment';
import * as ids from './menuIds';

export function useNav() {
	const authenticated = useAuthenticated();
	const org = useCurrentOrg();
	const orgId = useOrgId();
	const { addItems } = useNavItems();
	const isLocalOrStaging = [Environment.Local, Environment.Staging].includes(
		environment
	);

	useEffect(() => {
		if (!authenticated || !orgId || !isLocalOrStaging) return;

		if (org === OrgsLoading) {
			return addItems([loadingItem(ids.LOADING)]);
		}

		if (orgId !== '38856') return; // only show in ui org owner

		return addItems(
			[
				{
					id: ids.DEMO,
					label: t`Vue Demo`,
					path: `/orgs/${orgId}/vue/demo`,
					icon: IconMdWorkspaces,
					priority: 40,
					urlMatch: /^\/orgs\/\d+\/vue\/demo(\/|$|\?)/
				}
			].filter(Boolean)
		);
	}, [org, orgId, authenticated]);
}
