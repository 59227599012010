const json = {
  "charset": "utf-8",
  "headers": {
    "Content-Type": "text/plain; charset=UTF-8",
    "Plural-Forms": "nplurals=2; plural=(n != 1);"
  },
  "translations": {
    "": {
      "": {
        "msgid": "",
        "msgstr": [
          "Content-Type: text/plain; charset=UTF-8\nPlural-Forms: nplurals=2; plural=(n != 1);\n"
        ]
      },
      "Add a SaaS integration": {
        "msgid": "Add a SaaS integration",
        "msgstr": [
          "Agregar una integración SaaS"
        ]
      },
      "Add your team and assign roles.": {
        "msgid": "Add your team and assign roles.",
        "msgstr": [
          "Agregue su equipo y asigne roles."
        ]
      },
      "Administration": {
        "msgid": "Administration",
        "msgstr": [
          "Administración"
        ]
      },
      "Automate governance across your multi-cloud environment.": {
        "msgid": "Automate governance across your multi-cloud environment.",
        "msgstr": [
          "Automatice la gobernanza en su entorno multinube."
        ]
      },
      "Automation": {
        "msgid": "Automation",
        "msgstr": [
          "Automatización"
        ]
      },
      "Cloud": {
        "msgid": "Cloud",
        "msgstr": [
          "En la nube"
        ]
      },
      "Connect with customers, experts, and thought leaders to find answers and guidance": {
        "msgid": "Connect with customers, experts, and thought leaders to find answers and guidance",
        "msgstr": [
          "Conecte con clientes, expertos y líderes de opinión para encontrar respuestas y orientación"
        ]
      },
      "Connect your cloud bills": {
        "msgid": "Connect your cloud bills",
        "msgstr": [
          "Conectar las facturas en la nube"
        ]
      },
      "Deploy beacons and start collecting asset data.": {
        "msgid": "Deploy beacons and start collecting asset data.",
        "msgstr": [
          "Implemente estaciones de inventariado y comience a recoger datos de activos."
        ]
      },
      "Documentation Library": {
        "msgid": "Documentation Library",
        "msgstr": [
          "Biblioteca de documentación"
        ]
      },
      "Explore Automation": {
        "msgid": "Explore Automation",
        "msgstr": [
          "Explorar la automatización"
        ]
      },
      "Flexera One Forum": {
        "msgid": "Flexera One Forum",
        "msgstr": [
          "Foro de Flexera One"
        ]
      },
      "Get Started": {
        "msgid": "Get Started",
        "msgstr": [
          "Primeros pasos"
        ]
      },
      "Hello ${ 0 }": {
        "msgid": "Hello ${ 0 }",
        "msgstr": [
          "¡Hola, ${ 0 }!"
        ]
      },
      "Helpful Links": {
        "msgid": "Helpful Links",
        "msgstr": [
          "Vínculos útiles"
        ]
      },
      "IT Asset Management": {
        "msgid": "IT Asset Management",
        "msgstr": [
          "Administración de activos de TI"
        ]
      },
      "IT Asset Requests": {
        "msgid": "IT Asset Requests",
        "msgstr": [
          "Solicitudes de activos de TI"
        ]
      },
      "IT Visibility": {
        "msgid": "IT Visibility",
        "msgstr": [
          "Visibilidad de TI"
        ]
      },
      "Inventory Beacons": {
        "msgid": "Inventory Beacons",
        "msgstr": [
          "Estaciones de inventariado"
        ]
      },
      "Invite users to Flexera One": {
        "msgid": "Invite users to Flexera One",
        "msgstr": [
          "Invitar a usuarios a Flexera One"
        ]
      },
      "JOIN US TODAY": {
        "msgid": "JOIN US TODAY",
        "msgstr": [
          "ÚNASE A NOSOTROS HOY MISMO"
        ]
      },
      "Learning Center": {
        "msgid": "Learning Center",
        "msgstr": [
          "Centro de aprendizaje"
        ]
      },
      "Manage access to Flexera One using your identity provider.": {
        "msgid": "Manage access to Flexera One using your identity provider.",
        "msgstr": [
          "Administre el acceso a Flexera One con su proveedor de identidad."
        ]
      },
      "Open A New Case": {
        "msgid": "Open A New Case",
        "msgstr": [
          "Abrir un caso nuevo"
        ]
      },
      "Product Discovery": {
        "msgid": "Product Discovery",
        "msgstr": [
          "Descubrimiento del producto"
        ]
      },
      "SaaS": {
        "msgid": "SaaS",
        "msgstr": [
          "SaaS"
        ]
      },
      "Set up device discovery": {
        "msgid": "Set up device discovery",
        "msgstr": [
          "Configurar la detección de dispositivos"
        ]
      },
      "Set up your identity provider": {
        "msgid": "Set up your identity provider",
        "msgstr": [
          "Configurar el proveedor de identidad"
        ]
      },
      "Start importing bill data from your cloud provider.": {
        "msgid": "Start importing bill data from your cloud provider.",
        "msgstr": [
          "Comience a importar datos de facturas desde su proveedor de la nube."
        ]
      },
      "Submit Ideas": {
        "msgid": "Submit Ideas",
        "msgstr": [
          "Enviar ideas"
        ]
      },
      "Support Help": {
        "msgid": "Support Help",
        "msgstr": [
          "Ayuda de soporte técnico"
        ]
      },
      "Support Plan Information": {
        "msgid": "Support Plan Information",
        "msgstr": [
          "Información sobre el plan de soporte técnico"
        ]
      },
      "Technology Spend": {
        "msgid": "Technology Spend",
        "msgstr": [
          "Gasto en tecnología"
        ]
      },
      "Using Flexera One APIs": {
        "msgid": "Using Flexera One APIs",
        "msgstr": [
          "Uso de las API  de Flexera One"
        ]
      },
      "Visit the community": {
        "msgid": "Visit the community",
        "msgstr": [
          "Visite la comunidad"
        ]
      },
      "You do not have access to any products within this organization": {
        "msgid": "You do not have access to any products within this organization",
        "msgstr": [
          "No tiene acceso a ningún producto de esta organización"
        ]
      },
      "You do not have the correct privileges to view this page. Please contact your Flexera administrator to request the correct privileges.": {
        "msgid": "You do not have the correct privileges to view this page. Please contact your Flexera administrator to request the correct privileges.",
        "msgstr": [
          "No cuenta con los privilegios adecuados para ver esta página. Para solicitarlos, póngase en contacto con su administrador de  Flexera."
        ]
      }
    }
  }
};
export default json;
