import { EnforceSso, IamAuth, InactivityTimeout } from '@flexera/auth.iam';
import { FlagsProvider } from '@flexera/flexera-one.feature-flags';
import {
	setFNMSCookieRefreshUrl,
	setFNMSHostOverride
} from '@flexera/fnms.bootstrap';
import { FlexeraFonts } from '@flexera/lib.fonts';
import { TrackJs } from '@flexera/lib.trackjs';
import { renderShell, GlobalStyle } from '@flexera/shell.main';
import { OrgsProvider } from '@flexera/shell.orgs';
import { SharedResourceProvider } from '@flexera/shell.shared-resource';
import { NotificationsProvider } from '@flexera/shell.notifications';
import { OrgRoutes } from '@flexera/shell.orgs-routes';
import { BaseTheme } from '@flexera/ui.component-library';
import { WhitelabelProvider } from '@flexera/shell.whitelabel';
import { UserPreferencesProvider } from '@flexera/shell.user-preferences';
import { disableReactDevTools } from '@flexera/lib.utilities';
import { UATProvider } from '@flexera/environments.uat';
import { GlobalGridStyle } from '@flexera/lib.ag-grid';
import { ThemeProvider } from 'styled-components';
import React from 'react';
import { InitApp } from './app-design/v2/InitApp';
import './index.branch.html';
import './terraform-config';
import './assets/favicon.ico';

// These function calls allow for FNMS host override values to
// be set via query parameters from the login screen
setFNMSCookieRefreshUrl();
setFNMSHostOverride();

if (process.env.NODE_ENV === 'production') {
	disableReactDevTools();
}

renderShell(
	<>
		<FlexeraFonts />
		<IamAuth>
			<TrackJs />
			<OrgsProvider>
				<UserPreferencesProvider>
					<SharedResourceProvider>
						<NotificationsProvider>
							<FlagsProvider>
								<WhitelabelProvider>
									<>
										<ThemeProvider theme={BaseTheme}>
											<>
												<GlobalStyle />
												<GlobalGridStyle />
												<EnforceSso />
												<UATProvider>
													<InactivityTimeout />
													<OrgRoutes />
													<InitApp />
												</UATProvider>
											</>
										</ThemeProvider>
									</>
								</WhitelabelProvider>
							</FlagsProvider>
						</NotificationsProvider>
					</SharedResourceProvider>
				</UserPreferencesProvider>
			</OrgsProvider>
		</IamAuth>
	</>
);
