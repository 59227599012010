import { useAuthenticated } from '@flexera/auth.base';
import {
	useHideTIPlatform,
	useRestrictITV
} from '@flexera/flexera-one.feature-flags';
import { Environment, environment } from '@flexera/lib.environment';
import { Permissions, usePermissionSummary } from '@flexera/lib.permissions';
import {
	DATA_COLLECTION__UDI,
	DATA_COLLECTION__UDI__STATUS
} from '@flexera/shell.base-nav-tree';
import {
	LabelType,
	loadingItem,
	useNavItems,
	MenuItem
} from '@flexera/shell.navigation';
import { OrgsLoading, useCurrentOrg, useOrgId } from '@flexera/shell.orgs';
import { useEffect } from 'react';
import { t } from 'ttag';
import { helpDocBaseUrl } from '@flexera/lib.utilities';
import * as ids from './menuIds';

export function useNav() {
	const authenticated = useAuthenticated();
	const hideTIPlatform = useHideTIPlatform();
	// org comes from state
	const org = useCurrentOrg();
	// orgId comes from URL
	const orgId = useOrgId();
	const { addItems } = useNavItems();

	const restrictITVAccess = useRestrictITV();

	const [perms, loadingPerms] = usePermissionSummary(
		`/orgs/${orgId}`,
		Permissions.canSeeBeaconsPage,
		Permissions.hasDiscovery,
		Permissions.hasUnifiedObjectModel,
		Permissions.canSeeNormalizeTransparencyHardware,
		Permissions.canSeeNormalizeTransparencySoftware,
		Permissions.canEmbedNormalizeTransparencyReports
	);

	useEffect(() => {
		const defaultRouteSet = [] as MenuItem[];

		if (!authenticated || !orgId) return;

		if (org === OrgsLoading || loadingPerms) {
			return addItems([loadingItem(ids.LOADING)]);
		}

		if (
			!perms.get(Permissions.hasDiscovery) ||
			([Environment.Production, Environment.EUProduction].includes(environment) &&
				org.id === 30356) // Don't show UDI for this org in Prod (will be cleaned up in UI-2091))
		) {
			return;
		}

		if (restrictITVAccess) return;

		if (
			perms.get(Permissions.canSeeNormalizeTransparencySoftware) ||
			perms.get(Permissions.canSeeNormalizeTransparencyHardware)
		) {
			defaultRouteSet.push({
				id: ids.TRANSPARENCY,
				parentId: DATA_COLLECTION__UDI__STATUS,
				path: `/orgs/${orgId}/udi/statistics`,
				helpHref: `${helpDocBaseUrl}/ITVisibility/InventoryStats.htm`,
				urlMatch: /^\/orgs\/\d+\/udi\/statistics(\/|$|\?)/,
				label: t`Inventory Normalization Statistics`,
				priority: 40
			});

			if (
				!hideTIPlatform &&
				perms.get(Permissions.hasUnifiedObjectModel) &&
				perms.get(Permissions.canEmbedNormalizeTransparencyReports)
			) {
				defaultRouteSet.push({
					id: ids.NORMALIZATION_STATISTICS,
					parentId: DATA_COLLECTION__UDI__STATUS,
					helpHref: `${helpDocBaseUrl}/ITVisibility/InventoryStats.htm`,
					path: `/orgs/${orgId}/norm/normalization-insights`,
					urlMatch: /^\/orgs\/\d+\/norm\/normalization-insights(\/|$|\?)/,
					label: t`Inventory Normalization Insights`,
					priority: 41,
					labelType: LabelType.NEW
				});
			}
		}

		if (perms.get(Permissions.canSeeBeaconsPage)) {
			defaultRouteSet.push({
				parentId: DATA_COLLECTION__UDI,
				id: ids.TARGETS,
				path: `/orgs/${orgId}/udi/inventoryTargets`,
				urlMatch: /^\/orgs\/\d+\/udi\/inventoryTargets(\/|$|\?)/,
				helpHref: `${helpDocBaseUrl}/Beacons/ImportInvTargets.htm`,
				label: t`Inventory Targets`
			});
			defaultRouteSet.push({
				parentId: ids.TARGETS,
				id: ids.IMPORT_TARGETS,
				path: `/orgs/${orgId}/udi/inventoryTargets/import`,
				urlMatch: /^\/orgs\/\d+\/udi\/inventoryTargets\/import(\/|$|\?)/,
				helpHref: `${helpDocBaseUrl}/Beacons/ImportInvTargets.htm`,
				label: t`Import Inventory Targets`
			});
			defaultRouteSet.push({
				parentId: DATA_COLLECTION__UDI,
				id: ids.GROUPS,
				path: `/orgs/${orgId}/udi/groups`,
				urlMatch: /^\/orgs\/\d+\/udi\/groups$/,
				helpHref: `${helpDocBaseUrl}/Beacons/ImportInvTargets.htm`,
				label: t`Inventory Groups`
			});

			defaultRouteSet.push({
				parentId: ids.GROUPS,
				id: ids.EDIT_GROUPS,
				path: `/orgs/${orgId}/udi/groups/:group_id`,
				urlMatch: /^\/orgs\/\d+\/udi\/groups\/(|$|\?)/,
				helpHref: `${helpDocBaseUrl}/Beacons/ImportInvTargets.htm`,
				hidden: true,
				variableTitle: true
			});

			defaultRouteSet.push({
				parentId: ids.GROUPS,
				id: ids.CREATE_GROUPS,
				path: `/orgs/${orgId}/udi/groups/create`,
				urlMatch: /^\/orgs\/\d+\/udi\/groups\/create(|$|\?)/,
				helpHref: `${helpDocBaseUrl}/Beacons/ImportInvTargets.htm`,
				hidden: true,
				label: t`Create Group`
			});
			defaultRouteSet.push({
				parentId: DATA_COLLECTION__UDI,
				id: ids.TASKLIST,
				path: `/orgs/${orgId}/udi/tasklist`,
				urlMatch: /^\/orgs\/\d+\/udi\/tasklist$/,
				label: t`Task Lists`
			});
			defaultRouteSet.push({
				parentId: ids.TASKLIST,
				id: ids.EDIT_TASKLIST,
				path: `/orgs/${orgId}/udi/tasklist/:tasklist_id`,
				urlMatch: /^\/orgs\/\d+\/udi\/tasklist\/(|$|\?)/,
				hidden: true,
				variableTitle: true
			});
			defaultRouteSet.push({
				parentId: ids.TASKLIST,
				id: ids.CREATE_TASKLIST,
				path: `/orgs/${orgId}/udi/tasklist/create`,
				urlMatch: /^\/orgs\/\d+\/udi\/tasklist\/create(|$|\?)/,
				hidden: true,
				label: t`Create Task List`
			});
			defaultRouteSet.push({
				parentId: DATA_COLLECTION__UDI,
				id: ids.IMPORTS,
				path: `/orgs/${orgId}/udi/connections`,
				urlMatch: /^\/orgs\/\d+\/udi\/connections$/,
				helpHref: `${helpDocBaseUrl}/ITVisibility/ExtInvConnections.htm`,
				label: t`External Inventory Connections`
			});
			defaultRouteSet.push({
				parentId: ids.IMPORTS,
				id: ids.IMPORT_DETAILS,
				path: `/orgs/${orgId}/udi/connections/:import_id`,
				urlMatch: /^\/orgs\/\d+\/udi\/connections\/(|$|\?)/,
				helpHref: `${helpDocBaseUrl}/ITVisibility/ExtInvConnections.htm`,
				hidden: true,
				variableTitle: true
			});
			defaultRouteSet.push({
				parentId: DATA_COLLECTION__UDI,
				id: ids.BEACONS,
				path: `/orgs/${orgId}/udi/beacons`,
				urlMatch: /^\/orgs\/\d+\/udi\/beacons$/,
				helpHref: `${helpDocBaseUrl}/Beacons/BeaconsPage.htm`,
				label: t`IT Visibility Beacons`
			});
			defaultRouteSet.push({
				parentId: ids.BEACONS,
				id: ids.BEACON_DETAILS,
				path: `/orgs/${orgId}/udi/beacons/:beaconId`,
				urlMatch: /^\/orgs\/\d+\/udi\/beacons\/(|$|\?)/,
				helpHref: `${helpDocBaseUrl}/Beacons/BeaconsPage.htm`,
				hidden: true,
				variableTitle: true
			});
			defaultRouteSet.push({
				parentId: DATA_COLLECTION__UDI,
				id: ids.HISTORY,
				path: `/orgs/${orgId}/udi/versionHistory`,
				urlMatch: /^\/orgs\/\d+\/udi\/versionHistory(\/|$|\?)/,
				helpHref: `${helpDocBaseUrl}/Beacons/BeaconVersions.htm`,
				label: t`Beacon Versions`
			});
			defaultRouteSet.push({
				parentId: DATA_COLLECTION__UDI,
				id: ids.INSTALLERS,
				path: `/orgs/${orgId}/udi/installers`,
				urlMatch: /^\/orgs\/\d+\/udi\/installers(\/|$|\?)/,
				label: t`Installers`
			});
			defaultRouteSet.push({
				parentId: DATA_COLLECTION__UDI,
				id: ids.AGENTS,
				path: `/orgs/${orgId}/udi/agent-configurations`,
				urlMatch: /^\/orgs\/\d+\/udi\/agent-configurations$/,
				helpHref: `${helpDocBaseUrl}/Beacons/AgentConfig.htm`,
				label: t`Agent Configurations`
			});
			defaultRouteSet.push({
				parentId: ids.AGENTS,
				id: ids.AGENTS_EDIT,
				path: `/orgs/${orgId}/udi/agent-configurations/:agentConfigId`,
				urlMatch: /^\/orgs\/\d+\/udi\/agent-configurations\/(|$|\?)/,
				helpHref: `${helpDocBaseUrl}/Beacons/AgentConfig.htm`,
				hidden: true,
				variableTitle: true
			});
			defaultRouteSet.push({
				parentId: ids.AGENTS,
				id: ids.AGENTS_CREATE,
				path: `/orgs/${orgId}/udi/agent-configurations/create`,
				urlMatch: /^\/orgs\/\d+\/udi\/agent-configurations\/create(|$|\?)/,
				helpHref: `${helpDocBaseUrl}/Beacons/AgentConfig.htm`,
				hidden: true,
				label: t`Create Configuration`
			});
		}

		return addItems(defaultRouteSet.filter(Boolean));
	}, [
		org,
		orgId,
		authenticated,
		loadingPerms,
		perms,
		restrictITVAccess,
		hideTIPlatform
	]);
}
