import { useAuthenticated } from '@flexera/auth.base';
import {
	useCloudBcCount,
	useCloudCostPlanning,
	useCcoRecommendationSet
} from '@flexera/flexera-one.feature-flags';
import { Permissions, usePermissionSummary } from '@flexera/lib.permissions';
import {
	ADMIN__CLOUD,
	CLOUD__COMMITMENTS,
	CLOUD__COST,
	DASHBOARDS
} from '@flexera/shell.base-nav-tree';
import {
	LabelType,
	loadingItem,
	MenuItemDef,
	useNavItems
} from '@flexera/shell.navigation';
import {
	GET_USERS_BILLING_CENTERS,
	useCcoShareResources
} from '@flexera/optima.main';
import { OrgsLoading, useCurrentOrg, useOrgId } from '@flexera/shell.orgs';
import { helpDocBaseUrl } from '@flexera/lib.utilities';
import { useEffect } from 'react';
import { t } from 'ttag';
import * as ids from './menuIds';

export function useNav() {
	const authenticated = useAuthenticated();
	const hasCloudCostPlanning = useCloudCostPlanning();

	// BC count permission test: related to FLEX-3525 & SQ-6728
	const canUseCloudBcCount = useCloudBcCount();
	const loadResources =
		canUseCloudBcCount === undefined ? false : canUseCloudBcCount;
	const ccoResources = useCcoShareResources(
		[GET_USERS_BILLING_CENTERS],
		loadResources
	);

	// Recommendation sets feature flag
	const canUseRecommendationSets = useCcoRecommendationSet();

	// org comes from state
	const org = useCurrentOrg();
	// orgId comes from URL
	const orgId = useOrgId();
	const { addItems } = useNavItems();
	const [perms, loading] = usePermissionSummary(
		`/orgs/${orgId}`,
		// BC count permission test: related to FLEX-3525 & SQ-6728
		Permissions.canSeeAffiliatedBillingCenter,
		Permissions.canSeeOptimaBillingCenters,
		// End to FLEX-3525 & SQ-6728
		Permissions.canSeeOptimaRIs,
		Permissions.canSeeOptimaSavingPlans,
		Permissions.canSeeOptimaAdjustmentPrograms,
		Permissions.canSeeOptimaCloudOptimization,
		Permissions.canSeeOptimaCustomDimensions,
		Permissions.canSeeOptimaBillingConfig,
		Permissions.hasOptima,
		Permissions.commonOrgOwner,
		Permissions.canViewCloudCosts,
		Permissions.canViewBudgetsList,
		Permissions.canSeeRBDimensions,
		Permissions.canViewRecommendationSets,
		Permissions.canCreateRecommendationSet
	);

	useEffect(() => {
		if (!authenticated || !orgId) return;

		if (org === OrgsLoading || loading) {
			return addItems([loadingItem(ids.LOADING)]);
		}

		if (!perms.get(Permissions.hasOptima)) return;

		/*
		BC count permission test: related to FLEX-3525 & SQ-6728
		*/
		let canViewDashboards = true;
		let canViewOptimization =
			perms.get(Permissions.canSeeAffiliatedBillingCenter) ||
			perms.get(Permissions.canSeeOptimaCloudOptimization);
		let canSeeBCPages = perms.get(Permissions.canSeeOptimaBillingCenters);
		if (canUseCloudBcCount) {
			const userBillingCenterCount = ccoResources?.usersBillingCenters
				? ccoResources?.usersBillingCenters.length
				: 0;
			canViewDashboards = userBillingCenterCount > 0;
			canViewOptimization = perms.get(Permissions.canSeeOptimaCloudOptimization);
			canSeeBCPages = userBillingCenterCount > 0;
		}
		/*
			End of FLEX-3525 & SQ-6728
		*/

		// Dashboards -> Cloud
		const dashboardMenuItems: MenuItemDef[] = [
			canViewDashboards && {
				id: ids.DASHBOARDS,
				parentId: DASHBOARDS,
				helpHref: `${helpDocBaseUrl}/Optima/OptimaDashboards.htm`,
				path: `/orgs/${orgId}/optima/dashboards`,
				urlMatch: /^\/orgs\/\d+\/optima\/dashboards(\/|$|\?)/,
				label: t`Cloud`,
				priority: 30
			}
		];

		// Cloud -> Cost Optimization
		const cloudCostMenuItems: MenuItemDef[] = [
			canViewOptimization && {
				id: ids.OVERVIEW,
				parentId: CLOUD__COST,
				path: `/orgs/${orgId}/optima/optimization`,
				helpHref: `${helpDocBaseUrl}/Optima/ViewPotentialSavings.htm`,
				urlMatch: /^\/orgs\/\d+\/optima\/optimization(\/|$|\?)/,
				label: t`Optimization`,
				priority: 1
			},
			canViewOptimization && {
				id: ids.OVERVIEW_RECOMMENDATIONS,
				parentId: ids.OVERVIEW,
				path: `/orgs/${orgId}/optima/optimization/total-potential-savings`,
				helpHref: `${helpDocBaseUrl}/Optima/ViewPotentialSavings.htm`,
				urlMatch: /^\/orgs\/\d+\/optima\/optimization\/total-potential-savings(\/|$|\?)/,
				label: t`Total Potential Savings`,
				hidden: true
			},
			canSeeBCPages && {
				id: ids.ANOMALIES,
				parentId: CLOUD__COST,
				path: `/orgs/${orgId}/optima/anomalies`,
				helpHref: `${helpDocBaseUrl}/Optima/CostAnomaly.htm`,
				urlMatch: /^\/orgs\/\d+\/optima\/anomalies(\/|$|\?)/,
				label: t`Cloud Cost Anomalies`,
				hidden: true
			},
			perms.get(Permissions.canViewBudgetsList) && {
				id: ids.BUDGETS,
				parentId: CLOUD__COST,
				path: `/orgs/${orgId}/optima/budgets`,
				helpHref: `${helpDocBaseUrl}/Optima/cloudbudgets.htm`,
				urlMatch: /^\/orgs\/\d+\/optima\/budgets$/,
				label: hasCloudCostPlanning ? t`Cost Planning` : t`Budgets`,
				priority: 2
			},
			{
				parentId: ids.BUDGETS,
				id: ids.BUDGET,
				path: '/optima/budgets/:budgetId',
				helpHref: `${helpDocBaseUrl}/Optima/cloudbudgets.htm`,
				urlMatch: /^\/orgs\/\d+\/optima\/budgets\/(|$|\?)/,
				hidden: true,
				variableTitle: true
			},
			canSeeBCPages && {
				id: ids.BILLING_CENTERS,
				parentId: CLOUD__COST,
				path: `/orgs/${orgId}/optima/billing/billing-centers`,
				helpHref: `${helpDocBaseUrl}/Optima/billcentersguide.htm`,
				urlMatch: /^\/orgs\/\d+\/optima\/billing(\/|$|\?)/,
				label: t`Billing Centers`,
				priority: 3
			},
			canSeeBCPages && {
				id: ids.CCO_TABULAR_VIEW,
				parentId: CLOUD__COST,
				path: `/orgs/${orgId}/optima/tabular-view`,
				helpHref: `${helpDocBaseUrl}/Optima/TabularView.htm`,
				urlMatch: /^\/orgs\/\d+\/optima\/tabular-view(\/|$|\?)/,
				label: t`Tabular View`,
				priority: 4
			},
			perms.get(Permissions.canSeeOptimaAdjustmentPrograms) && {
				id: ids.ADJUSTMENTS,
				parentId: CLOUD__COST,
				path: `/orgs/${orgId}/optima/adjustments`,
				helpHref: `${helpDocBaseUrl}/Optima/OptimaBillAdjustments.htm`,
				urlMatch: /^\/orgs\/\d+\/optima\/adjustments(\/|$|\?)/,
				label: t`Adjustment Program`,
				priority: 6
			},
			perms.get(Permissions.canViewCloudCosts) && {
				id: ids.MIGRATION_PLANNING,
				parentId: CLOUD__COST,
				path: `/orgs/${orgId}/optima/cloud-migration-planning`,
				urlMatch: /^\/orgs\/\d+\/optima\/cloud-migration-planning(\/|$|\?)/,
				label: t`Cloud Migration Planning`,
				priority: 7
			},
			perms.get(Permissions.canViewRecommendationSets) &&
				canUseRecommendationSets && {
					id: ids.RECOMMENDATION_SET,
					parentId: CLOUD__COST,
					path: `/orgs/${orgId}/optima/recommendation-sets`,
					label: t`Recommendation Sets`,
					urlMatch: /^\/orgs\/\d+\/optima\/recommendation-sets(\/|$|\?)/
				},
			perms.get(Permissions.canViewRecommendationSets) &&
				canUseRecommendationSets && {
					id: ids.CREATE_RECOMMENDATION_SET,
					parentId: CLOUD__COST,
					path: `/orgs/${orgId}/optima/create-recommendation-set`,
					label: t`Create Recommendation Sets`,
					urlMatch: /^\/orgs\/\d+\/optima\/create-recommendation-set(\/|$|\?)/,
					hidden: true
				},
			perms.get(Permissions.canViewRecommendationSets) &&
				canUseRecommendationSets && {
					id: ids.EDIT_RECOMMENDATION_SET,
					parentId: CLOUD__COST,
					path: `/orgs/${orgId}/optima/edit-recommendation-set`,
					label: t`Edit Recommendation Sets`,
					urlMatch: /^\/orgs\/\d+\/optima\/edit-recommendation-set(\/|$|\?)/,
					hidden: true
				}
		];

		// Cloud -> Commitments
		const cloudCommitmentsMenuItems: MenuItemDef[] = [
			perms.get(Permissions.canSeeOptimaRIs) && {
				id: ids.RIS,
				parentId: CLOUD__COMMITMENTS,
				path: `/orgs/${orgId}/optima/reserved-instances/aws`,
				helpHref: `${helpDocBaseUrl}/Optima/resinstances.htm`,
				urlMatch: /^\/orgs\/\d+\/optima\/reserved-instances(\/|$|\?)/,
				label: t`Reserved Instances`,
				priority: 1
			},
			perms.get(Permissions.canSeeOptimaSavingPlans) && {
				id: ids.SAVINGS_PLANS,
				parentId: CLOUD__COMMITMENTS,
				path: `/orgs/${orgId}/optima/savings-plans/aws`,
				urlMatch: /^\/orgs\/\d+\/optima\/savings-plans(\/|$|\?)/,
				label: t`Savings Plans`,
				priority: 2
			}
		];

		// Administration -> Cloud Settings
		const cloudSettings: MenuItemDef[] = [
			perms.get(Permissions.canSeeRBDimensions) && {
				id: ids.RULE_BASED_DIMENSIONS,
				parentId: ADMIN__CLOUD,
				helpHref: `${helpDocBaseUrl}/Optima/OptimaRuleBasedDimensions.htm`,
				path: `/orgs/${orgId}/optima/cloud-settings/rule-based-dimensions`,
				urlMatch: /^\/orgs\/\d+\/optima\/cloud-settings\/rule-based-dimensions(\/|$|\?)/,
				label: t`Rule-Based Dimensions`,
				labelType: LabelType.BETA,
				priority: 1
			},
			perms.get(Permissions.canSeeOptimaCustomDimensions) && {
				id: ids.TAG_DIMENSIONS,
				parentId: ADMIN__CLOUD,
				helpHref: `${helpDocBaseUrl}/Optima/TagDimensions.htm`,
				path: `/orgs/${orgId}/optima/cloud-settings/tag-dimensions`,
				urlMatch: /^\/orgs\/\d+\/optima\/cloud-settings\/tag-dimensions(\/|$|\?)/,
				label: t`Tag Dimensions`,
				priority: 2
			},
			perms.get(Permissions.canSeeOptimaBillingConfig) && {
				id: ids.BILLING_CONFIG,
				parentId: ADMIN__CLOUD,
				path: `/orgs/${orgId}/optima/cloud-settings/billing-config`,
				helpHref: `${helpDocBaseUrl}/Optima/OptimaBillDataConnections.htm`,
				urlMatch: /^\/orgs\/\d+\/optima\/cloud-settings\/billing-config(\/|$|\?)/,
				label: t`Billing Configuration`,
				priority: 3
			},
			perms.get(Permissions.commonOrgOwner) && {
				id: ids.BILL_PROCESSING,
				parentId: ADMIN__CLOUD,
				helpHref: `${helpDocBaseUrl}/Optima/BillProcessing.htm`,
				path: `/orgs/${orgId}/optima/cloud-settings/bill-processing`,
				urlMatch: /^\/orgs\/\d+\/optima\/cloud-settings\/bill-processing(\/|$|\?)/,
				label: 'Bill Processing Status',
				priority: 4
			}
		];

		return addItems(
			[
				...dashboardMenuItems,
				...cloudCostMenuItems,
				...cloudCommitmentsMenuItems,
				...cloudSettings
			].filter(Boolean)
		);
	}, [
		canUseCloudBcCount,
		ccoResources,
		org,
		orgId,
		authenticated,
		loading,
		perms
	]);
}
