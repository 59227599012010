const json = {
  "charset": "utf-8",
  "headers": {
    "Content-Type": "text/plain; charset=UTF-8",
    "Plural-Forms": "nplurals=2; plural=(n != 1);"
  },
  "translations": {
    "": {
      "": {
        "msgid": "",
        "msgstr": [
          "Content-Type: text/plain; charset=UTF-8\nPlural-Forms: nplurals=2; plural=(n != 1);\n"
        ]
      },
      "Add Dashboard": {
        "msgid": "Add Dashboard",
        "msgstr": [
          "Dashboard hinzufügen"
        ]
      },
      "Connections": {
        "msgid": "Connections",
        "msgstr": [
          "Verbindungen"
        ]
      },
      "Dashboard": {
        "msgid": "Dashboard",
        "msgstr": [
          "Dashboard"
        ]
      },
      "Data Explorer": {
        "msgid": "Data Explorer",
        "msgstr": [
          "Daten-Explorer"
        ]
      },
      "Data Exports": {
        "msgid": "Data Exports",
        "msgstr": [
          "Datenexporte"
        ]
      },
      "Data Mashup": {
        "msgid": "Data Mashup",
        "msgstr": [
          "Daten-Mashup"
        ]
      },
      "Hardware Evidence": {
        "msgid": "Hardware Evidence",
        "msgstr": [
          "Hardware-Nachweise"
        ]
      },
      "Software Evidence": {
        "msgid": "Software Evidence",
        "msgstr": [
          "Software-Nachweise"
        ]
      }
    }
  }
};
export default json;
