import { withCapability } from '@flexera/lib.permissions';
import { lazyLoaded } from '@flexera/lib.utilities';

export const InventoryTargets = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/udi.main');
		return { default: (await mod).InventoryTargetsPage };
	}),
	'di'
);

export const ImportInventoryTargets = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/udi.main');
		return { default: (await mod).ImportTargetsPage };
	}),
	'di'
);

export const Groups = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/udi.main');
		return { default: (await mod).GroupsPage };
	}),
	'di'
);

export const CreateGroups = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/udi.main');
		return { default: (await mod).CreateGroupPage };
	}),
	'di'
);

export const EditGroups = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/udi.main');
		return { default: (await mod).EditGroupPage };
	}),
	'di'
);

export const TaskLists = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/udi.main');
		return { default: (await mod).TaskListPage };
	}),
	'di'
);

export const CreateTaskLists = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/udi.main');
		return { default: (await mod).CreateTaskListPage };
	}),
	'di'
);

export const EditTaskLists = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/udi.main');
		return { default: (await mod).EditTaskListPage };
	}),
	'di'
);

export const Imports = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/udi.main');
		return { default: (await mod).ImportsPage };
	}),
	'di'
);

export const ImportDetails = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/udi.main');
		return { default: (await mod).ImportDetails };
	}),
	'di'
);

export const Beacons = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/udi.main');
		return { default: (await mod).BeaconsPage };
	}),
	'di'
);

export const CreateBeacons = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/udi.main');
		return { default: (await mod).CreateBeaconsPage };
	}),
	'di'
);

export const ReconfigureBeacons = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/udi.main');
		return { default: (await mod).ReconfigureBeaconsPage };
	}),
	'di'
);

export const BeaconDetails = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/udi.main');
		return { default: (await mod).BeaconDetailsPage };
	}),
	'di'
);

export const BeaconVersions = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/udi.main');
		return { default: (await mod).VersionHistoryPage };
	}),
	'di'
);

export const Installers = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/udi.main');
		return { default: (await mod).InstallerPage };
	}),
	'di'
);

export const AgentConfigList = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/udi.main');
		return { default: (await mod).AgentConfigList };
	}),
	'di'
);

export const CreateAgentConfig = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/udi.main');
		return { default: (await mod).CreateAgentConfig };
	}),
	'di'
);

export const EditAgentConfig = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/udi.main');
		return { default: (await mod).EditAgentConfig };
	}),
	'di'
);

export const ITVNormalizeTransparency = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/udi.main');
		return { default: (await mod).NormalizeTransparency };
	}),
	'di'
);

export const ITVNormalizeStatisticsProvider = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/udi.main');
		return { default: (await mod).NormalizeStatisticsDashboard };
	}),
	'di'
);

export const ConfigureBeacon = lazyLoaded(async () => {
	const mod = import('@flexera/udi.main');
	return { default: (await mod).ConfigureBeaconPage };
});
